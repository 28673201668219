import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoSrcURL from "../images/op.mp4"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div class="overflow-hidden">
      <video
        playsinline
        className="video-player"
        height="100%"
        width="100%"
        muted
        autoPlay="autoplay"
      >
        <source
          src={VideoSrcURL}
          type="video/mp4"
        />
      </video>
      <div className="p-6 sm:p-10 leading-8">
        <p className="font-bold">「CondeHouse Style 2021 September」を開催いたします。</p>
        <p>地球環境に配慮した企業活動と、感性豊かなデザインの具現化をテーマに、創業50周年を迎えた2018年より「リブランディング」に取り組んできました。その成果のひとつが、自然素材を使った家具がもたらす、心地よい暮らしを表現する「18のライフスタイルシーン」の提案。リビング、ダイニングをはじめ、需要が高まるワークスペースなど新作とともに新たなしつらえにてご提案いたします。</p>
        <p>6月に発表し今回より発売となる新作「セレクトファニチャーA+」は、天然木100%の木質感と手仕事の美しさが魅力の新シリーズ。全国の拠点にて展示開始となりますので、この機会にぜひご覧ください。</p>
        <div className="w-11/12 mx-auto border-black border-2 p-8">
          <p className="font-bold">CondeHouse Style 2021 September 概要</p>
          <p>日程：9月16日（木）～28日（火）<br />会場：旭川、札幌、道央、東京、横浜、名古屋、京都、大阪、福岡<br />※営業時間の詳細はWEBサイトをご確認ください。</p>
          <p>ご来場は「事前予約制」でのご案内とさせていただきます。参加を希望されるショップの予約フォームから、ご都合の良い日時をお申し込みください。<br />※福岡のみお電話（092-732-1333）にてご予約を承ります。</p>
          <p className="text-center"><a href="https://condehouse.bmetrack.com/c/l?u=CC48688&e=130063B&c=E54C0&t=0&l=33B502C&email=W%2FvZTQesPeaYJmdt8%2F2tqAYMShjRzAyo&seq=1" target="_blank" className="ch-button shadow-lg hover:bg-green-700 duration-300">→ 来場予約はこちら</a></p>
          <p>イベントは新型コロナウイルス感染対策を十分に講じて開催いたします。感染予防を考慮し「予約制」としておりますので、WEBサイト・お電話・メールにてお申込みください。また、ご来店のお客さまにおかれましては、マスク着用、手の消毒、体調のすぐれない方はお控えいただけますよう、ご理解、ご協力をお願いいたします。</p>
       </div>
       <div className="w-11/12 mx-auto p-8 bg-clip-border bg-green-50 mt-12" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
          <p className="font-bold">CHプレゼンテーションのご案内</p>
          <p>・CondeHouse Style 2021 Septemberについて　（所要時間10分）<br />・新シリーズ「セレクトファニチャーA+」について　（所要時間30～60分）<br /><span className="text-gray-500">※ご来場の皆さまにプレゼンテーションをご覧いただきます。所要時間は、ご都合に合わせて対応させていただきます。</span></p>
       </div>
      </div>
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
        <p className="text-lg font-bold">トップメッセージ</p>
        <p>「CondeHouse Style 2021 September」の概要を当社代表染谷よりお伝えいたします。</p>
        <iframe width="100%" class="h-60 sm:h-96" src="https://www.youtube.com/embed/93rsSes1aZM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    {/* collage
     */}
     <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease"> <p className="text-lg font-bold">スタイル提案</p></div>
    
    <div class="overflow-hidden">
      <StaticImage
        src="../images/homeliving0905ｔ_5ol.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        
        <p>カンディハウスは、「座る」ということを中心に様々な空間をトータルにご提案いたします。「ノース ノーブル」「シンプル & ナチュラル」「アドバンストクラシック」という3つのスタイルをベースに「18のライフスタイルシーン」を表現。この秋、新作も交えて展開するライフスタイルシーンを、カンディハウスショップにてご体感ください。        </p>
      </div>
    </div>
    {/* North Noble */}
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/scene1.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>
      <div className="">
        <p className="font-bold text-base">North Noble</p>
        <p className="leading-8">北国の凛とした空気を思い起こさせる、清潔で気品ある空間。緊張感のあるフォルムや精緻な技に、やわらかな知性が漂います。</p>
      </div>
    </div>
    <div className="p-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/image_nn.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/scene01.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />
            <p className="text-right"><a target="_blank" href="https://youtu.be/ZG6uU5rEtqw
"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            /> Scene 01</a></p> 
            </div>
        <div className="overflow-hidden"><StaticImage
              src="../images/scene04.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/4shdwfxxMj4"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            /> Scene 04</a></p> 
            </div>
    </div>

    {/* simple natural */}
    <div className="p-6 sm:px-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/th_scene_SN04.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>
      <div className="">
        <p className="font-bold text-base">Simple & Natural</p>
        <p className="leading-8">さらりとした木の床を素足で歩くような。
あなたが自分らしくいられる場所を、やさしくシンプルに表現します。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/image_sn.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/79scene09.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/qRzq3h1qA_k"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            /> Scene 9</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene11.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/Qh1WoZQD0os"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            />  Scene 11</a></p> 
            </div>
    </div>
    {/* advanced classic */}
    <div className="p-3"></div>

    <div className="p-6 sm:px-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/th_scene_AC04.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>
      <div className="">
        <p className="font-bold text-base">Advanced Classic</p>
        <p className="leading-8">伝統に裏打ちされた、説得力ある美しさ。繊細な手仕事と先進的なデザインの融合に、心と体が満たされます。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/image_ac.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
              src="../images/72scene16.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/L0I3zVmpIWs"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            /> Scene 16</a></p> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/scene18.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            <p className="text-right"><a target="_blank" href="https://youtu.be/gyM9o4VuBqs"><StaticImage
        src="../images/play.png"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ width: `1rem` }}
              className="topimg magnify"
            /> Scene 18</a></p> 
            </div>
    </div>
{/* shops */}
    <div className="p-6 sm:px-10 sm:grid sm:grid-cols-2 sm:gap-5">
      <div className="overflow-hidden">
        <StaticImage
          src="../images/tokyoPh02.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
        <p>Tokyo Shop</p>
      </div>
      <div className="overflow-hidden">
        <StaticImage
          src="../images/nagoya02.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
        <p>Nagoya Shop</p>
      </div>
    </div>
       {/* san dining section starts */}
       <div class="p-6 pb-0 leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        <p className="text-lg font-bold">New Collection Debut</p>
        <p>旭川の家具メーカー、㈱メーベルトーコー社と共同開発した新収納シリーズ「セレクトファニチャー A+（エープラス）」や、道産広葉樹である北海道カバの無垢材を使用した「サンダイニング」、ワークスペースで活躍するワイヤリングシステムを搭載した無垢テーブルなど、ホームユースのみならず公共施設やオフィス、レストランなど幅広い空間に向けた新作を発売いたします。</p>
        <p className="text-lg font-bold pt-6">Select Furniture A+<br />セレクトファニチャー A+（エープラス）</p>
      </div>
    <div class="overflow-hidden">
      <StaticImage
        src="../images/system.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
    </div>
    <div>
      <div class="p-6 py-0 leading-8" data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease">
        
        <p>システムファニャーamが生まれ変わります。メーベルトーコー社との共同開発で生まれた 新モデルを発表。新たな仕様を加え、数ある組み合わせからお好みに合わせ、簡単に選べるセレクトファニチャーへと進化しました。</p>
      </div>
    </div>
    {/* triple */}
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/aplus02.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/aplus03.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/aplus06.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><a href="https://www.condehouse.co.jp/news/condehouse-news/new-collection-debut/#link_Aplus" target="_blank" className="ch-button shadow-lg">→ 製品情報はこちら</a></p>
    <div className="p-5"></div>
    <div className="px-6 sm:px-10">
     <p className="font-bold text-base">SAN サンダイニング <br/>Design: Michael Schneider</p>
    </div>
    <div class="overflow-hidden">
      
      <StaticImage
        src="../images/san3.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
    </div>
    <div className="px-6 sm:px-10">
      <div className="">
       
        <p className="leading-8">ドイツ人デザイナーのミヒャエル・シュナイダー氏による洗練されたデザインが特長。地元、北海道広葉樹のカバ材を使用した住宅から店舗オフィスまで活躍する、新作チェアーを発表いたします。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/san.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/san1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/san2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center"><a href="https://www.condehouse.co.jp/products/detail.php?product_id=713" target="_blank" className="ch-button shadow-lg">→ 製品情報はこちら</a></p>
    {/* san dining section ends */}
     {/* A+ section starts */}
    {/* A+ section ends */}
    {/* One section starts */}
       <div className="border-2 m-10 mt-20 shadow-lg">
          <p className="pt-5 text-base font-bold text-center">6月開催の[CondeHouse Style 2021 June Online] Menu</p>
          <ul class="sm:grid sm:grid-cols-3  text-center">
           <li className="flex-auto"><a href="#newproducts">6月発表の追加アイテム</a></li>
             <li className="flex-auto"><a href="#factorytour">バーチャルファクトリーツアー</a></li>
            <li className="flex-auto"><a href="#presentation">プレゼンテーション</a></li>
            <li className="flex-auto"><a href="#shoprefresh">旭川ショップリフレッシュ</a></li>
            <li className="flex-auto"><a href="#seatforest">インスタレーション〈座るの森〉</a></li>
          </ul>
        </div>
      <div className="px-6 sm:px-10" id="newproducts">
        <p className="font-bold text-base">6月発表の追加アイテム</p>
      </div>
       <div className="p-6 sm:p-10 sm:grid sm:grid-cols-2 sm:gap-5">
         
      <div className="overflow-hidden">
      <StaticImage
        src="../images/onetop.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      </div>
      <div className>
      <p className="font-bold text-base">ONE ワン ダイニング テーブル<br />Design: 倉本 仁</p>
        <p className="leading-8">出会った瞬間から心惹かれ、見てふれて納得するものを目指すという、デザイナー倉本仁氏と開発した丸テーブルに、小判形のテーブル2サイズを追加いたします。横長ながら一本脚はそのままに、様々な空間における設置が可能となりました。食事やミーティングなど様々な場面での選択の幅が広がります。</p>
      </div>
    </div>
    <div className="px-6 sm:px-10 sm:grid sm:grid-cols-3 sm:gap-2">
      <div className="overflow-hidden"><StaticImage
        src="../images/one1.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/one2.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
      <div className="overflow-hidden"><StaticImage
        src="../images/one3.jpg"
        alt="CondeHuse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      /> 
      </div>
    </div>
    <p className="text-center mb-20"><a href="https://www.condehouse.co.jp/products/detail.php?product_id=714" target="_blank" className="ch-button shadow-lg">→ 製品情報はこちら</a></p>
    {/* one section ends */}
    
    <div className="overflow-hidden" >
      <div className="flex overflow-hidden">
        <StaticImage
          src="../images/02.jpg"
          alt="CondeHouse New System Furniture"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
        <hr />
        <StaticImage
          src="../images/ten.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem`, width: '100%' }}
          className="topimg magnify"
        />
      </div>

      <div class="p-6 py-2 leading-8">
        <p>その他、今春発売を開始した新作SLテーブルや人気のテンシリーズの追加アイテムなど、住空間からオフィス空間まで、様々な場面で活躍するアイテムをご提案いたします。</p>
      </div> 
        {/* factory tour */}
        <div class="overflow-hidden">
        <StaticImage
            src="../images/factorytour.jpg"
            alt="CondeHouse Style 2021 Scene Image 1"
            style={{ marginBottom: `1.45rem` }}
            className="topimg magnify"
          />
        <div class="p-6 sm:p-10 shadow-2xl leading-8" id="factorytour">
          <p className="text-lg font-bold">バーチャルファクトリーツアー</p>
          <p>カンディハウスのロングセラーアイテム「ルントオム チェアー（座張）」製作における「製材・突板加工」から最終工程の「座板取付・出荷前検査」までを動画でご紹介。迫力あるものづくりの様子をぜひお楽しみください。</p>
          <iframe width="100%" height="400" src="https://www.youtube.com/embed/MucLe7HK2RY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div class="grid grid-cols-2 factory-links">
          <div>
            <ul>
              <li><a href="https://youtu.be/piYRHoMNlms" target="_blank">①Factory</a></li>
              <li><a href="https://youtu.be/RxdpkhV_KeM" target="_blank">②製材・突板加工</a></li>
              <li><a href="https://youtu.be/47pydPi3W_M" target="_blank">③木取加工</a></li>
              <li><a href="https://youtu.be/FBoilRYvRBs" target="_blank">④加工工程【笠木・肘・後脚】</a></li>
              <li><a href="https://youtu.be/KnaoE_xLCMA" target="_blank">⑤加工工程【前脚】</a></li>
            </ul>
          </div>
          <div>
            <ul>
              <li><a href="https://youtu.be/MPrQJ9FLvyg" target="_blank">⑥仕上工程【部材研磨・部分組立】</a></li>
              <li><a href="https://youtu.be/ZKdsJoTs0Cw" target="_blank">⑦仕上工程【本体組立・研磨】</a></li>
              <li><a href="https://youtu.be/muvhXs_IUdE" target="_blank">⑧塗装工程</a></li>
              <li><a href="https://youtu.be/JDH6SZlSrjc" target="_blank">⑨椅子張工程【座面の張りこみ】</a></li>
              <li><a href="https://youtu.be/BaTEH4Ozv_o" target="_blank">⑩最終工程【座板組付・出荷前検査】</a></li>
            </ul>
          </div>
         </div>
        </div>
        <div class="overflow-hidden" id="presentation"> 
      <div class="p-6 sm:p-10 shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
        <p className="text-lg font-bold">プレゼンテーション</p>
        <p>6月にオンライン開催した「CondeHouse Style 2021 June Online」のプレゼンテーション動画はこちら。6月発表発売となった新作のご紹介やリフレッシュした旭川ショップのご案内など、今年のカンディハウスの見どころを当社代表染谷よりお伝えいたします。</p>
        <iframe width="100%" class="h-60 sm:h-96" src="https://www.youtube.com/embed/6jquHvw1xks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    {/* shop refresh */}
    <div class="p-6 sm:p-10 leading-8" id="shoprefresh">
        <p className="text-lg font-bold">旭川ショップリフレッシュ</p>
        <p>「CondeHouse Style 2021 June Online」では本社・旭川ショップをリフレッシュし、心地よい暮らしを表現する「18のライフスタイルシーン」の空間が登場。その様子を皆さまにお伝えいたします。</p>

        <div class=" shadow-2xl leading-8" data-sal="slide-up"  data-sal-delay="500"  data-sal-easing="ease">
        <iframe width='100%' height='480' src='https://my.matterport.com/show/?m=tQk4KDTkJAe' frameborder='0' allowfullscreen allow='xr-spatial-tracking'></iframe>        
        </div>
        {/* scene pictures */}
        <div className="">
          <div className="sm:grid sm:grid-cols-3 sm:gap-2">
            <div className="overflow-hidden"><StaticImage
              src="../images/3f1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/3f2.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
            <div className="overflow-hidden"><StaticImage
              src="../images/2f1.jpg"
              alt="CondeHuse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            /> 
            </div>
          </div>
          <p>ワークスタイル</p>
          
        </div>
      </div>
    {/* seat forest */}
    <div class="overflow-hidden  shadow-lg" id="seatforest">
      <StaticImage
          src="../images/ph01.jpg"
          alt="CondeHouse Style 2021 Scene Image 1"
          style={{ marginBottom: `1.45rem` }}
          className="topimg magnify"
        />
        <div class="p-6 sm:p-10 shadow-2xl leading-8 sm:grid sm:grid-cols-11">
          <div className="col-span-8">
            <p className="text-lg font-bold">インスタレーション〈座るの森〉</p>
            <p>カンディハウスが世界へ向けて発信する「グローバル・アイコニック」製品を中心に、8種類の椅子をセレクトしてみなさまにご体感いただくインスタレーションです。北海道産のフレーム材にオリジナルカラー7色で塗装し、張地をコーディネート。あなたの探していた掛け心地が、この森で見つかりますように。</p>
          </div>
          <div className="col-span-3 seatforestimg">
            <StaticImage
              src="../images/ph02.jpg"
              alt="CondeHouse Style 2021 Scene Image 1"
              style={{ marginBottom: `1.45rem` }}
              className="topimg magnify"
            />
          </div>
         
        </div>
      </div>
        <div class="p-6 sm:p-10 leading-8">
         <p className="text-lg font-bold">CondeHouse Style 2021 公式アプリ</p>
         <div className="sm:grid sm:grid-cols-2 sm:gap-5">
          <div class="overflow-hidden">
            <StaticImage
                src="../images/ios.png"
                alt="CondeHouse Style 2021 Scene Image 1"
                style={{ marginBottom: `1.45rem`, maxWidth: `250px`  }}
                className="topimg magnify"
              />
          </div>
          <div>
            <p>「CondeHouse Style 2021 June Online」開催に併せ、カンディハウス公式アプリをリリースします。本アプリでは、「CondeHouse Style 2021」の各コンテンツを直接的に楽しんでいただくほか、モバイル端末ならではの通知機能や利便性を生かし、新たな配信手法に取り組みます。</p>
            <p class="text-right"><a href="https://apps.apple.com/jp/app/ch-style/id1561572259" class="ch-button" target="_blank">Apple App Storeからダウンロード</a></p>
            <p class="text-right"><a href="https://play.google.com/store/apps/details?id=com.condehouse.chs" class="ch-button" target="_blank">Google Playからダウンロード</a></p>
          </div>
         </div>
         
        </div>
       
      </div>
    </div>
  </Layout>
)

export default IndexPage
